<template>
  <div>
    <div>
      <el-form :inline="true" class="search">
        <el-form-item label="任务">
          <el-select clearable style="width: 120px" filterable v-model="searchData.task_id" placeholder="任务">
            <el-option
                v-for="(item, key) in tasks"
                :key="key"
                :value="item.id"
                :label="item.name">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态">
          <el-select style="width:120px;" v-model="searchData.status" placeholder="状态搜索" clearable>
            <el-option :value="1" label="待处理" />
            <el-option :value="2" label="进行中" />
            <el-option :value="3" label="待结算" />
            <el-option :value="10" label="已结算" />
            <el-option :value="20" label="已失效" />
          </el-select>
        </el-form-item>
        <el-form-item label="用户ID">
          <el-input v-model="searchData.member_id" placeholder="用户ID搜索"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="getTaskRecord()">查询</el-button>
        </el-form-item>

        <el-form-item style="float: right">
          <el-button @click="exportAlipay()">导出</el-button>
          <el-button @click="importAlipay()">导入</el-button>
        </el-form-item>
      </el-form>
    </div>

    <!----- 表格 start------->
    <el-table :data="tableData" :border="true" empty-text="暂无数据" style="width: 100%">
      <el-table-column prop="id" label="ID"/>
      <el-table-column prop="task.project_name" label="合同名"/>
      <el-table-column prop="task.id" label="任务ID"/>
      <el-table-column prop="task.name" label="任务名"/>
      <el-table-column label="用户">
        <template #default="scope">
          <el-avatar :src="scope.row.member.avatar"></el-avatar>
          {{ scope.row.member.nickname }}
        </template>
      </el-table-column>
      <el-table-column prop="status_desc" label="名称"/>
      <el-table-column prop="price" label="佣金"/>
      <el-table-column prop="created_at" label="创建时间"/>
    </el-table>

    <!----- 表格 end------->

    <!----- 分页 start------->
    <div class="cherry-page" v-show="pageData.total">
      <el-pagination
          small
          background
          layout="prev, pager, next, total"
          :total="pageData.total"
          v-model:current-page="searchData.page"
          :page-size="searchData.per_page"
          @update:current-page="getTaskRecord()"
          class="mt-4"
      />
    </div>
    <!----- 分页 end------->

  </div>
</template>

<script>
import {apiGetTaskRecordIndex, apiGetTaskIndex, apiExportTaskRecordAlipay} from "@/api/taskApi"
import {getCompanyId} from "@/plugins/company"

export default {
  created() {
    this.getTask()
    this.getTaskRecord()
  },
  data() {
    return {
      searchData:{
        task_id: '',
        keyword: '',
        status: '',
        page: 1,
        per_page: 15,
        company_id: getCompanyId(),
      },
      pageData:{
        total: 0
      },
      tasks:[],
      tableData:[],
    }
  },
  methods:{
    getTaskRecord () {
      apiGetTaskRecordIndex(this.searchData).then(res =>{
        this.pageData = res.meta
        this.tableData = res.data
      })
    },
    exportAlipay () {
      window.open(apiExportTaskRecordAlipay(this.searchData))
    },
    importAlipay () {
      this.$router.push({
        name: 'task.record.import',
      })
    },
    getTask () {
      apiGetTaskIndex({company_id: getCompanyId(), status:1, per_page: 200}).then(res =>{
        this.tasks = res.data
      })
    },
  }
}
</script>
